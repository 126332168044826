/**
 * @ComponentFor AppShellViewModel
 */
import React from 'react';
import { styled } from '@glitz/react';
import { translate } from '@avensia/scope';
import { Main, Section, Appearance as PageAppearance } from 'Shared/PageLayout';
import { PageType } from 'Shared/State';

const Center = styled(Main, {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
  justifyContent: 'center',
});

// This component is rendered when the visitor has a service worker enabled browser
// and she is making a full page load for a url that we don't have in the local cache.
// So the app shell (header and footer) will be rendered and this component gets
// rendered during the time we load the page she wanted.
export default class AppShell extends React.Component<PageType, void> {
  render() {
    return (
      <Center appearance={PageAppearance.Narrow}>
        {!this.props.isLoading &&
          (this.props.loadFailure && this.props.loadFailure.status === 404 ? (
            <Section>
              <h1>{translate('/Errors/PageNotFoundHeader')}</h1>
              <p>{translate('/Errors/PageNotFoundText')}</p>
            </Section>
          ) : !navigator.onLine ? (
            <Section>
              <h1>{translate('/Errors/OfflineHeader')}</h1>
              <p>{translate('/Errors/OfflineText')}</p>
            </Section>
          ) : (
            <Section>
              <h1>{translate('/Errors/UnknownLoadFailureHeader')}</h1>
              <p>{translate('/Errors/UnknownLoadFailureText')}</p>
            </Section>
          ))}
      </Center>
    );
  }
}
